var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","close-on-content-click":_vm.closeOnContentClick,"width":"300","offset-y":"","z-index":"1","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"hide-details":"","height":"10","prepend-inner-icon":"mdi-filter","placeholder":_vm.$_strings.order.FILTER,"outlined":"","dense":"","readonly":""},on:{"click":function($event){_vm.closeOnContentClick = false}}},'v-text-field',attrs,false),on))]}}])},[_c('v-list',{staticClass:"overflow-auto",attrs:{"width":"300","min-height":"150","max-height":"400"}},[_c('section',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"caption font-weight-bold"},[_vm._v("Alamat")])],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","outlined":"","placeholder":"Cari Alamat","hide-details":"","clearable":""},model:{value:(_vm.filters.address),callback:function ($$v) {_vm.$set(_vm.filters, "address", $$v)},expression:"filters.address"}})],1)],1)],1),_c('section',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"caption font-weight-bold"},[_vm._v("Provinsi")])],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_c('common-auto-complete-items',{staticClass:"caption",attrs:{"type":"listProvinces","searchName":"name","item-value":"name","item-text":"name","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.$_strings.locations.PROVINCE_INPUT_LABEL,"filter":_vm.provinceFilterItems,"items":_vm.provinceList},on:{"updateItems":_vm.updateProvinceList,"change":_vm.provinceChanged},model:{value:(_vm.filters.province),callback:function ($$v) {_vm.$set(_vm.filters, "province", $$v)},expression:"filters.province"}})],1)],1)],1),_c('section',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"caption font-weight-bold"},[_vm._v("Kab/Kota")])],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_c('common-auto-complete-items',{staticClass:"caption",attrs:{"type":_vm.form.provinceId ? 'listCity' : null,"optional":{
              provinceId: _vm.form.provinceId
            },"searchName":"name","item-value":"name","item-text":"name","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.$_strings.locations.CITY_INPUT_LABEL,"filter":_vm.cityFilterItems,"items":_vm.cityList},on:{"updateItems":_vm.updateCityList,"change":_vm.cityChanged},model:{value:(_vm.filters.city),callback:function ($$v) {_vm.$set(_vm.filters, "city", $$v)},expression:"filters.city"}})],1)],1)],1),_c('section',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"caption font-weight-bold"},[_vm._v("Kecamatan")])],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_c('common-auto-complete-items',{staticClass:"caption",attrs:{"type":_vm.form.cityId ? 'listDistricts' : null,"optional":{
              cityId: _vm.form.cityId
            },"searchName":"name","item-value":"name","item-text":"name","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.$_strings.locations.DISTRICT_INPUT_LABEL,"filter":_vm.districtFilterItems,"items":_vm.districtList},on:{"updateItems":_vm.updateDistrictList,"change":_vm.districtChanged},model:{value:(_vm.filters.district),callback:function ($$v) {_vm.$set(_vm.filters, "district", $$v)},expression:"filters.district"}})],1)],1)],1),_c('section',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"caption font-weight-bold"},[_vm._v("Tipe Lokasi")])],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_c('v-select',{staticClass:"caption",attrs:{"dense":"","outlined":"","items":_vm.$_strings.locations.TYPE_LIST,"hide-details":""},model:{value:(_vm.filters.locationType),callback:function ($$v) {_vm.$set(_vm.filters, "locationType", $$v)},expression:"filters.locationType"}})],1)],1)],1),_c('section',{staticClass:"pl-2 pr-2"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","block":""},on:{"click":_vm.setFilter}},[_vm._v(" "+_vm._s(_vm.$_strings.common.APPLY)+" ")]),_c('v-btn',{staticClass:"mt-2 white--text",attrs:{"depressed":"","color":"red","block":""},on:{"click":function($event){_vm.closeOnContentClick = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CLOSE)+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }