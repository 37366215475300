<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="auto">
        <v-btn
          color="primary"
          small
          to="/main/lokasi/create"
          v-if="userAccess.canCreate"
        >
          {{$_strings.locations.CREATE_LOCATION_BUTTON_LABEL}}
        </v-btn>
        <v-btn
          :loading="downloading"
          color="primary"
          small
          @click="downloadDataLocation"
          class="ml-2"
        >
          Download Data
        </v-btn>
      </v-col>
      <MasterDataCsvUpload
        :importTypeProp="$_strings.importType.LOCATOIONS"
        :uploadCsvProp="uploadCsv"
        :downloadTemplateCsvProp="downloadTemplateCsv"
        dataTypeProp="location"
        @fetchList="fetching"
      />
      <v-col
        cols="12"
        md="3"
        class="ml-md-auto"
      >
        <v-row
          dense
          justify="end"
        >
          <v-col cols="auto">
            <FilterList
              @setNewFilters="setNewFilters"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              hide-details
              outlined
              class="caption"
              v-on:keyup.enter="searchLocation"
              v-model="search"
              :placeholder="$_strings.locations.SEARCH_LOCATION_INPUT_PLACEHOLDER"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="searchLocation">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <List
          :key="key_list"
          :filters="filters"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterList from './FilterList.vue';
import List from './List.vue';

const MasterDataCsvUpload = () => import(/* webpackChunkName: "MasterDataCsvUpload" */ '../Common/MasterDataCsvUpload');

export default {
  components: {
    FilterList,
    List,
    MasterDataCsvUpload,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.search || '',
      downloading: false,
      items: [],
      filters: {
        address: this.$route.query.address || '',
        province: this.$route.query.province || '',
        city: this.$route.query.city || '',
        district: this.$route.query.district || '',
        locationType: this.$route.query.locationType || '',
      },
    };
  },
  methods: {
    fetching() {
      this.key_list += 1;
    },
    searchLocation() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
            page: 1,
          },
        });
      }
      this.fetching();
    },
    setNewFilters(newFilters) {
      const newQuery = {
        ...this.$route.query,
        ...newFilters,
        page: 1,
      };
      if (JSON.stringify(newQuery).replace(/["]/g, '') !== JSON.stringify(this.$route.query).replace(/["]/g, '')) {
        this.$router.replace({
          query: newQuery,
        });
        this.filters = {
          ...this.filters,
          ...newFilters,
        };
      }
      this.fetching();
    },
    uploadCsv(formData) {
      return this.$_services.locations.uploadCsv(formData);
    },
    downloadTemplateCsv() {
      return this.$_services.locations.fetchCsvTemplateData();
    },
    downloadDataLocation() {
      this.downloading = true;
      const {
        address,
        province,
        city,
        district,
        locationType,
      } = this.filters;
      this.$_services.locations.locationDownload({
        name: this.search,
        address,
        province,
        city,
        district,
        locationType,
      })
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Data_Lokasi.csv';
          link.click();
        }).finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>
