<template>
  <v-data-table
    :loading="isLoading"
    :headers="displayedHeader"
    :items="items"
    item-key="id"
    :server-items-length="totalItems"
    :options.sync="pagination"
    @click:row="rowClicked"
    :footer-props="{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': $_item_per_page,
      disablePagination: isLoading,
      disableItemsPerPage: isLoading
    }"
  >
    <template v-slot:[`item.locationType`]="{item}">
      <span class="font-weight-medium">
        {{convertTypeLocation(item.locationType)}}
      </span>
    </template>
    <template v-slot:[`item.createdBy`]="{item}">
      <span class="font-weight-medium">
        {{item.createdBy}}
      </span>
      <br>
      <span class="text-caption">
        {{dateFormat(item.createdAt)}}
      </span>
    </template>
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{$_strings.locations.PAGE_NAME}}
        <span v-if="items.length">
          {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import {
  dateFormat,
  convertTypeLocation,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'LocationList',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.locations.LOCATION_NAME_TABLE_HEADER_LABEL,
          value: 'name',
        },
        {
          text: this.$_strings.locations.ADDRESS_TABLE_HEADER_LABEL,
          value: 'address',
          width: '14vw',
        },
        {
          text: this.$_strings.locations.PROVINCE_TABLE_HEADER_LABEL,
          value: 'province.name',
          width: '12vw',
        },
        {
          text: this.$_strings.locations.CITY_TABLE_HEADER_LABEL,
          value: 'city.name',
          width: '13vw',
        },
        {
          text: this.$_strings.locations.DISTRICT_TABLE_HEADER_LABEL,
          value: 'district.name',
          width: '12vw',
        },
        {
          text: this.$_strings.locations.TYPE_TABLE_HEADER,
          value: 'locationType',
          width: '6vw',
        },
        {
          text: this.$_strings.common.CREATED_BY_TABLE_HEADER_LABEL,
          value: 'createdBy',
          width: '10vw',
        },
      ],
      items: [],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchLocations();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    convertTypeLocation,
    handlerPagination,
    defaultPagination,
    async rowClicked(item) {
      const { canUpdate } = this.userAccess;
      if (!canUpdate) return;
      const { id } = item;
      try {
        this.$root.$loading.show();
        const result = await this.$_services.locations.fetchLocationDetail(id);
        const { data } = result;
        this.$router.push({
          name: 'location-edit-page',
          params: {
            id,
            data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    fetchLocations(search = this.$route.query.search || '') {
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = this.pagination;
      const {
        address,
        province,
        city,
        district,
        locationType,
      } = this.filters;
      let sort = '';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      this.isLoading = true;
      this.$_services.locations.fetchLocationList({
        page: page - 1,
        size: itemsPerPage,
        name: search,
        sort,
        address,
        province,
        city,
        district,
        locationType,
      }, source)
        .then((res) => {
          this.items = res.data.contents;
          this.totalItems = res.data.totalData;
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
