<template>
  <v-menu
    bottom
    :close-on-content-click="closeOnContentClick"
    width="300"
    offset-y
    z-index="1"
    allow-overflow>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        :placeholder="$_strings.order.FILTER"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false"
        v-on="on"
      ></v-text-field>
    </template>
    <v-list
      width="300"
      min-height="150"
      max-height="400"
      class="overflow-auto"
    >
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">Alamat</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-text-field
              dense
              outlined
              class="caption"
              placeholder="Cari Alamat"
              v-model="filters.address"
              hide-details
              clearable
            />
          </v-list-item-content>
        </v-list-item>
      </section>
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">Provinsi</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <common-auto-complete-items
              type="listProvinces"
              searchName="name"
              item-value="name"
              item-text="name"
              outlined
              dense
              hide-details
              class="caption"
              v-model="filters.province"
              clearable
              :placeholder="$_strings.locations.PROVINCE_INPUT_LABEL"
              :filter="provinceFilterItems"
              :items="provinceList"
              @updateItems="updateProvinceList"
              @change="provinceChanged"
            />
          </v-list-item-content>
        </v-list-item>
      </section>
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">Kab/Kota</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <common-auto-complete-items
              :type="form.provinceId ? 'listCity' : null"
              :optional="{
                provinceId: form.provinceId
              }"
              searchName="name"
              item-value="name"
              item-text="name"
              outlined
              dense
              hide-details
              class="caption"
              v-model="filters.city"
              clearable
              :placeholder="$_strings.locations.CITY_INPUT_LABEL"
              :filter="cityFilterItems"
              :items="cityList"
              @updateItems="updateCityList"
              @change="cityChanged"
            />
          </v-list-item-content>
        </v-list-item>
      </section>
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">Kecamatan</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <common-auto-complete-items
              :type="form.cityId ? 'listDistricts' : null"
              :optional="{
                cityId: form.cityId
              }"
              searchName="name"
              item-value="name"
              item-text="name"
              outlined
              dense
              hide-details
              class="caption"
              v-model="filters.district"
              clearable
              :placeholder="$_strings.locations.DISTRICT_INPUT_LABEL"
              :filter="districtFilterItems"
              :items="districtList"
              @updateItems="updateDistrictList"
              @change="districtChanged"
            />
          </v-list-item-content>
        </v-list-item>
      </section>
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">Tipe Lokasi</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-select
              dense
              outlined
              class="caption"
              v-model="filters.locationType"
              :items="$_strings.locations.TYPE_LIST"
              hide-details
            >
            </v-select>
          </v-list-item-content>
        </v-list-item>
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          {{$_strings.common.APPLY}}
        </v-btn>
        <v-btn
          depressed
          class="mt-2 white--text"
          color="red"
          block
          @click="closeOnContentClick = true"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  created() {
    this.setFromQueryParams();
  },
  data() {
    return {
      closeOnContentClick: false,
      provinceList: [],
      cityList: [],
      districtList: [],
      provinceFilterItems: {
        page: 0,
        size: 50,
        totalData: 0,
      },
      cityFilterItems: {
        page: 0,
        size: 50,
        totalData: 0,
      },
      districtFilterItems: {
        page: 0,
        size: 50,
        totalData: 0,
      },
      form: {
        provinceId: this.$route.query.provinceId || '',
        cityId: this.$route.query.cityId || '',
        districtId: this.$route.query.districtId || '',
      },
      filters: {
        address: this.$route.query.address || '',
        province: this.$route.query.province || '',
        city: this.$route.query.city || '',
        district: this.$route.query.district || '',
        locationType: this.$route.query.locationType || '',
      },
    };
  },
  methods: {
    setFromQueryParams() {
      const {
        provinceId,
        cityId,
        districtId,
        province,
        city,
        district,
      } = this.$route.query;
      if (provinceId) {
        this.provinceList = [{
          id: provinceId,
          name: province,
        }];
      }
      if (cityId) {
        this.cityList = [{
          id: cityId,
          name: city,
        }];
      }
      if (districtId) {
        this.districtList = [{
          id: districtId,
          name: district,
        }];
      }
    },
    provinceChanged(value) {
      if (value) {
        const findProv = this.provinceList.find((prov) => prov.name === value);
        this.form.provinceId = findProv.id;
      } else {
        this.filters.province = '';
        this.form.provinceId = '';
      }
      this.form.cityId = '';
      this.form.districtId = '';
      this.filters.city = '';
      this.filters.district = '';
      this.cityList = [];
      this.districtList = [];
      this.cityFilterItems = {
        page: 0,
        size: 50,
        totalData: 0,
      };
      this.districtFilterItems = {
        page: 0,
        size: 50,
        totalData: 0,
      };
    },
    updateProvinceList(items) {
      this.provinceList = [...this.provinceList, ...items];
    },
    cityChanged(value) {
      if (value) {
        const findCity = this.cityList.find((city) => city.name === value);
        this.form.cityId = findCity.id;
      } else {
        this.form.cityId = '';
        this.filters.city = '';
      }
      this.filters.district = '';
      this.form.districtId = '';
      this.districtList = [];
      this.districtFilterItems = {
        page: 0,
        size: 50,
        totalData: 0,
      };
    },
    updateCityList(items) {
      this.cityList = [...this.cityList, ...items];
    },
    districtChanged(value) {
      if (value) {
        const findDistrict = this.districtList.find((district) => district.name === value);
        this.form.districtId = findDistrict.id;
        return;
      }
      this.form.districtId = '';
      this.filters.district = '';
    },
    updateDistrictList(items) {
      this.districtList = [...this.districtList, ...items];
    },
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('setNewFilters', {
        ...this.filters,
        ...this.form,
      });
    },
  },
};
</script>
