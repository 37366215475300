var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
    showCurrentPage: true,
    showFirstLastPage: true,
    'items-per-page-options': _vm.$_item_per_page,
    disablePagination: _vm.isLoading,
    disableItemsPerPage: _vm.isLoading
  }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.locationType",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.convertTypeLocation(item.locationType))+" ")])]}},{key:"item.createdBy",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.createdAt))+" ")])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.locations.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }